export class EnvironmentBase {
  static palfinger_api_management = '';
  static palfinger_api_management_subs_key = '';
  static palfinger_app_gateway = '';
  static oidc_clientid = '';
  static oidc_scope = '';
  static google_analytics_id = '';
  static geo_json_url = '';

  static domainsForApiManagementSubsKey: string[];

  static identityServerAuthConf: {
    whitelistedDomains: string[];
    whitelistedRoutes: string[];
    blacklistedRoutes: string[];
  } = {
    whitelistedDomains: [],
    whitelistedRoutes: [],
    blacklistedRoutes: [],
  };

  static LINKS: {
    SECURITY_TOKEN_SERVICE: string;
    POST_LOGOUT_REDIRECT_URI: string;
    PALDESK_BASEPATH: string;
    BPM: string;
    TKS: string;
  } = {
    SECURITY_TOKEN_SERVICE: '',
    POST_LOGOUT_REDIRECT_URI: '',
    PALDESK_BASEPATH: '',
    BPM: '',
    TKS: '',
  };

  static FEATUREFLAGS: {
    enable_general_onboarding_screen: string;
    track_user_without_consent: string;
    display_configuration_debugging_toggle: string;
  } = {
    enable_general_onboarding_screen: '',
    track_user_without_consent: '',
    display_configuration_debugging_toggle: '',
  };
}
