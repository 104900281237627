<h1 mat-dialog-title>
  {{ 'sales_shared.solution.chosen_sales_options' | translate }}
</h1>
<div mat-dialog-content class="tks-learn-more-dialog-content">
  <mat-list *ngIf="salesOptions.length">
    <mat-list-item *ngFor="let option of salesOptions" class="no-padding">
      <span matListItemTitle>{{ option.code }}</span>
      <div matListItemMeta>{{ option.price }} EUR</div>
      <p matListItemLine>{{ option.name }}</p>
    </mat-list-item>
  </mat-list>
</div>
<mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ 'general.close' | translate }}
  </button>
</mat-dialog-actions>
