import { registerLocaleData } from '@angular/common';
import { HttpClientTestingModule } from '@angular/common/http/testing';
import localeDe from '@angular/common/locales/de';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterTestingModule } from '@angular/router/testing';
import { UserService } from '@features/auth';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { CustomArticlesService } from '@sales-libs/custom-article/data-access';
import {
  PriceListsService,
  ProductsService,
} from '@sales-libs/product/data-access';
import {
  CalculationsService,
  CartsService,
  LostOrdersService,
  OffersService,
  ProjectsService,
} from '@sales-libs/project/data-access';
import { ShippingCostsService } from '@sales-libs/settings/data-access';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';
import { of } from 'rxjs';
import { SalestoolSharedModule } from './salestool-shared.module';
import { SalestoolSharedReducers } from './store';
import {
  PALDESK_BASE_BATH_TOKEN,
  PALFINGER_API_MANAGEMENT_SUBS_KEY,
} from './tokens';

registerLocaleData(localeDe);

@NgModule({
  imports: [
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
        },
      },
    ),
    StoreModule.forFeature('salestoolSharedModule', SalestoolSharedReducers),
    EffectsModule.forRoot(),
    HttpClientTestingModule,
    TranslateModule.forRoot(),
    RouterTestingModule,
    BrowserAnimationsModule,
    GoogleAnalytics4DirectiveModule,
  ],
  exports: [SalestoolSharedModule, TranslateModule, RouterTestingModule],
  providers: [
    { provide: CartsService, useValue: {} },
    { provide: OffersService, useValue: {} },
    { provide: ProjectsService, useValue: {} },
    { provide: LostOrdersService, useValue: {} },
    { provide: ShippingCostsService, useValue: {} },
    { provide: ProductsService, useValue: {} },
    { provide: CustomArticlesService, useValue: {} },
    { provide: PriceListsService, useValue: {} },
    { provide: CalculationsService, useValue: {} },
    {
      provide: UserService,
      useValue: {
        currentUser: of({ lang: 'EN' }),
        isAuthorized$: of(true),
        userContext: {
          lang: 'EN',
        },
        hasRole: () => true,
      },
    },
    // MICRO APP RELATED
    {
      provide: PALDESK_BASE_BATH_TOKEN,
      useFactory: () => 'https://paldesk-test.palfinger.com',
    },
    {
      provide: PALFINGER_API_MANAGEMENT_SUBS_KEY,
      useFactory: () => 'palfinger_api_management_subs_key',
    },
    { provide: LOCALE_ID, useValue: 'de-DE' },
  ],
})
export class SalestoolSharedTestingModule {}
