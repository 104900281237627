<h2 mat-dialog-title>
  {{ 'turnkey_solution.common.loosing_configuration_title' | translate }}
</h2>

<mat-dialog-content class="additional-info-content">
  <p>
    {{ 'turnkey_solution.common.loosing_configuration_text' | translate }}
    <br />
    {{ 'turnkey_solution.common.loosing_configuration_text_save' | translate }}
  </p>
  <div
    class="action-buttons-wrap"
    fxLayout="row wrap"
    fxLayoutGap="1rem"
    fxLayout.lt-sm="column wrap"
  >
    <button
      mat-stroked-button
      disableRipple="true"
      (click)="onCopy()"
      [gaClickListener]="Analytics.UrlShare"
      data-cy="copy-link-button"
    >
      <mat-icon class="link-icon">link</mat-icon>
      {{ 'turnkey_solution.common.copy_link' | translate }}
    </button>

    <button
      mat-stroked-button
      disableRipple="true"
      (click)="$event.preventDefault(); sendEmail()"
      [gaClickListener]="Analytics.UrlShare"
    >
      <mat-icon>email</mat-icon>
      {{ 'turnkey_solution.common.share_link' | translate }}
    </button>
  </div>
</mat-dialog-content>

<mat-dialog-actions
  fxLayout.lt-sm="column wrap"
  fxLayoutAlign.lt-sm="center end"
  [fxLayoutGap.lt-sm]="0.5 | dsSpacing"
>
  <button mat-button mat-dialog-close>
    {{ 'general.close' | translate }}
  </button>
  <button
    mat-raised-button
    color="accent"
    mat-dialog-close
    data-test="new-configuration-button"
    (click)="emitStartNewConfiguration()"
  >
    {{ 'turnkey_solution.common.button_start_new_configuration' | translate }}
  </button>
</mat-dialog-actions>
