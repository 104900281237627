import { Clipboard } from '@angular/cdk/clipboard';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsSnackbar, DsSnackbarType } from '@design-system/feature/snackbar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Analytics, deformatUrlFriendlyName } from '@sales-libs/shared/util';
import { GoogleAnalyticsClickListenerDirective } from '@shared-lib/google-analytics';

export const imports = [
  MatDialogModule,
  FlexLayoutModule,
  DsSpacingModule,
  TranslateModule,
  MatIconModule,
  MatButtonModule,
  GoogleAnalyticsClickListenerDirective,
];

@Component({
  selector: 'sl-shared-sc-additional-info-dialog',
  standalone: true,
  imports: imports,
  templateUrl: './additional-info-dialog.component.html',
  styleUrls: ['./additional-info-dialog.component.scss'],
})
export class SlSharedScAdditionalInfoDialogComponent {
  @Output() startNewConfiguration = new EventEmitter<void>();

  url: string;
  selectedModelName: string | null | undefined;
  companyName = 'PALFINGER';
  compWindow: any;
  readonly Analytics = Analytics;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      productLineName: string;
      selectedModelName: string | undefined;
      urlFriendlyProductLineEnum: { [key: string]: string };
      environmentLink: string;
    },
    public dialogRef: MatDialogRef<SlSharedScAdditionalInfoDialogComponent>,
    private router: Router,
    private readonly clipboard: Clipboard,
    private snackbar: DsSnackbar,
    private translatePipe: TranslateService,
  ) {
    this.url = this.data.environmentLink + this.router.url;
    this.compWindow = window;
  }

  onCopy(): void {
    this.clipboard.copy(this.url);

    this.snackbar.queue(
      this.translatePipe.instant('turnkey_solution.common.copy_link_message'),
      {
        type: DsSnackbarType.Info,
      },
    );
  }

  sendEmail(): void {
    if (this.data.selectedModelName === undefined) {
      this.selectedModelName =
        this.data.productLineName ===
        this.data.urlFriendlyProductLineEnum.TailLift
          ? deformatUrlFriendlyName(
              this.data.urlFriendlyProductLineEnum.TailLift,
            )
          : this.companyName;
    } else {
      this.selectedModelName = this.data.selectedModelName;
    }

    const emailSubject = this.translatePipe
      .instant('turnkey_solution.common.email_subject')
      .replace('{0}', this.selectedModelName);

    this.compWindow.location.href =
      'mailto:?Subject=' + emailSubject + '&body=%0D%0A%0D%0A' + this.url;
  }

  emitStartNewConfiguration(): void {
    this.startNewConfiguration.emit();
  }
}
