export enum Analytics {
  UrlShare = 'URL share',
  ContactFormSubmit = 'Contact Form Submit',
  PdfDownload = 'PDF Download',
  StartConfigurator = 'Start Configurator',
  GoToSummary = 'Go To Summary',
  ChangeModel = 'Change Model',
  StartConfiguration = 'Start Configuration',
  SubmitAnswers = 'Submit Answers',
}
