import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { RouterModule } from '@angular/router';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsEquipmentIconModule } from '@design-system/component/equipment-icon';
import { DsCardCarouselComponent } from '@design-system/components/card-carousel';
import { DsFilterInputModule } from '@design-system/components/filter-input';
import { DsImageCarouselModule } from '@design-system/components/image-carousel';
import { DsLoadingModule } from '@design-system/components/loading';
import { DsPageModule } from '@design-system/feature/page';
import { DsSnackbarModule } from '@design-system/feature/snackbar';
import { DsSpotlightModule } from '@design-system/feature/spotlight';
import { TranslateModule } from '@ngx-translate/core';
import {
  SlScModelDescriptionDialogComponent,
  SlScSalesOptionsDialogComponent,
} from '@sales-libs/sc-questionnaire/ui';
import { SlSharedScFooterComponent } from '@sales-libs/shared/ui';
import {
  SlScCurrencySymbolPipe,
  SlScDescriptionDecorationPipe,
  SlScMapPalfingerLangPipe,
  SlSharedScNavigationStepperComponent,
} from '@sales-libs/shared/util';
import { GoogleAnalyticsClickListenerDirective } from '@shared-lib/google-analytics';
import { MaterialModule } from '../material.module';

const sharedModules = [
  CommonModule,
  TranslateModule,
  MaterialModule,
  CdkStepperModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  FlexLayoutModule,
  DsFilterInputModule,
  DsSnackbarModule,
  DsLoadingModule,
  DsEquipmentIconModule,
  DsSpacingModule,
  DsPageModule,
  DsImageCarouselModule,
  DsCardCarouselComponent,
  GoogleAnalyticsClickListenerDirective,
  DsSpotlightModule,
];

@NgModule({
  imports: [
    sharedModules,
    RouterModule,
    NgOptimizedImage,
    SlScDescriptionDecorationPipe,
    SlScSalesOptionsDialogComponent,
    SlScModelDescriptionDialogComponent,
    SlScMapPalfingerLangPipe,
    SlSharedScNavigationStepperComponent,
    SlSharedScFooterComponent,
    SlScCurrencySymbolPipe,
  ],
  exports: [
    sharedModules,
    SlScMapPalfingerLangPipe,
    NgOptimizedImage,
    SlScDescriptionDecorationPipe,
    SlScSalesOptionsDialogComponent,
    SlScModelDescriptionDialogComponent,
    SlSharedScNavigationStepperComponent,
    SlSharedScFooterComponent,
    SlScCurrencySymbolPipe,
  ],

  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
  ],
})
export class SharedModule {}
