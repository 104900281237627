import { CustomArticleItem } from '../generated';

export const testCustomArticleItem: CustomArticleItem = {
  original_name: 'original_name',
  original_short_description: 'original_short_description',
  original_long_description: 'original_long_description',
  original_purchase_price: 1234,
  original_quantity: 10,
  original_sales_price: 99.99,
  original_is_discountable: true,
  original_is_ppi_relevant: true,
  is_edited: true,
  company_bpm_id: 12345,
  cart_item_price_comparer_id: '1',
  sort_key: 2,
  id: 'testId',
  cart_id: 789,
  custom_article_id: 2,
  name: 'testName',
  short_description: 'testShortDescription',
  long_description: 'testLongDescription',
  is_ppi_relevant: true,
  picture_name: 'testPictureName',
  picture_type: 'testPictureType',
  sales_price: 199.99,
  purchase_price: 149.99,
  is_discountable: true,
  quantity: 3,
  cart_item_group_id: null,
  is_optional: false,
  sort_key_optional: 0,
  is_purchasing_custom_article: false,
};
