<h1 mat-dialog-title>
  {{ answer?.more_details?.title }}
</h1>

<div mat-dialog-content>
  <div class="card-carousel-wrap">
    <ds-card-carousel [isSingleCard]="true">
      <mat-card
        fxLayout="column"
        fxLayoutAlign="space-between"
        *ngFor="let content of answer?.more_details?.content"
      >
        <img
          mat-card-image
          *ngIf="content.media_type === AnswerDetailsMediaType.Image"
          src="{{ content.media_uri }}"
          alt="{{ answer?.more_details?.title }}"
          data-test="tks_answer_details_image"
        />

        <video
          *ngIf="content.media_type === AnswerDetailsMediaType.Video"
          controls
          data-test="tks_answer_details_video"
          (play)="playVideo()"
          #videoPlayer
          class="answer-details-video"
        >
          <source src="{{ content.media_uri }}" type="video/mp4" />
          {{ 'turnkey_solution.common.video_not_supported' | translate }}
        </video>

        <mat-card-content class="answer-details-description">
          <p
            [innerHTML]="
              content.description
                ? (content.description | slScDescriptionDecoration)
                : (answer?.more_details?.description
                  | slScDescriptionDecoration)
            "
          ></p>
        </mat-card-content>
      </mat-card>
    </ds-card-carousel>
  </div>
</div>

<mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ 'general.close' | translate }}
  </button>
</mat-dialog-actions>
