import { Action, createReducer, on } from '@ngrx/store';
import { Section } from '@sales-libs/sc-questionnaire/data-access';
import {
  Guide,
  Guides,
  Languages,
} from '@sales-libs/shared/data-access/sc-generated';
import { ScSharedActions } from './sc-shared.actions';

export interface ScSharedState {
  productLineName: string;
  userGuid: string;
  currentSection: Section | undefined;
  selectedLanguageCode: string;
  languages: Languages | undefined;
  isLoading: boolean;
  userGuides?: Guides;
  guide?: Guide;
  sections?: Section[];
}

export const initialState: ScSharedState = {
  productLineName: '',
  userGuid: '',
  currentSection: undefined,
  selectedLanguageCode: '',
  languages: undefined,
  isLoading: false,
  userGuides: undefined,
  guide: undefined,
};

const reducer = createReducer(
  initialState,

  on(ScSharedActions.getUserGuides, (state, { language }) => ({
    ...state,
    language: language,
  })),
  on(ScSharedActions.getUserGuidesSuccess, (state, { userGuides }) => ({
    ...state,
    userGuides: userGuides,
  })),
  on(ScSharedActions.getUserGuidesError, (state, { payload }) => ({
    ...state,
    gettingUserGuidesError: payload,
  })),

  on(
    ScSharedActions.getGuide,
    (state, { guideId, sectionType, language, guid }) => ({
      ...state,
      guideId: guideId,
      language: language,
      sectionType: sectionType,
      guid: guid,
      isLoading: true,
    }),
  ),
  on(ScSharedActions.getGuideSuccess, (state, { guide }) => ({
    ...state,
    guide: guide,
    isLoading: false,
  })),
  on(ScSharedActions.getGuideError, (state, { payload }) => ({
    ...state,
    gettingGuideError: payload,
    isLoading: false,
  })),

  on(ScSharedActions.SetProductLineName, (state, { productLineName }) => ({
    ...state,
    productLineName: productLineName,
  })),
  on(ScSharedActions.SetUserGuid, (state, { userGuid }) => ({
    ...state,
    userGuid: userGuid,
  })),
  on(ScSharedActions.SetCurrentSection, (state, { currentSection }) => ({
    ...state,
    currentSection: currentSection,
  })),
  on(
    ScSharedActions.SetSelectedLanguageCode,
    (state, { selectedLanguageCode }) => ({
      ...state,
      selectedLanguageCode: selectedLanguageCode,
    }),
  ),
  on(
    ScSharedActions.SetScSharedState,
    (
      state,
      { productLineName, userGuid, currentSection, selectedLanguageCode },
    ) => ({
      ...state,
      productLineName: productLineName,
      userGuid: userGuid,
      currentSection: currentSection,
      selectedLanguageCode: selectedLanguageCode,
    }),
  ),

  on(ScSharedActions.getLanguages, (state, { guideId }) => ({
    ...state,
    guideId: guideId,
  })),
  on(ScSharedActions.getLanguagesSuccess, (state, { languages }) => ({
    ...state,
    languages: languages,
  })),
  on(ScSharedActions.getLanguagesError, (state, { payload }) => ({
    ...state,
    gettingLanguagesError: payload,
  })),

  on(ScSharedActions.SetGuide, (state, { guide }) => ({
    ...state,
    guide: guide,
  })),
);

export function scSharedReducer(
  state: ScSharedState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
